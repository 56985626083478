<template>
  <div class="sales">
    <v-card>
      <v-card-title>Laporan Sales</v-card-title>

      <v-divider></v-divider>

      <v-list>
        <v-list-item-group color="primary">
          <template v-for="report in reports">
            <v-list-item
              v-if="!isSalesman || (isSalesman && report.forSalesman)"
              :key="report.title"
              :to="report.link"
            >
              <v-list-item-icon>
                <v-badge v-if="report.notification === true" color="red">
                  <v-icon v-text="report.icon"></v-icon>
                </v-badge>
                <v-icon v-else v-text="report.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="report.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider
              v-if="
                report.divider &&
                  (!isSalesman || (isSalesman && report.forSalesman))
              "
              :key="report.id"
              class="mx-4"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Sales',
  data() {
    return {
      isSalesman: this.getSalesmanStatus(),

      reports: [
        {
          id: 1,
          icon: 'mdi-file-document-multiple',
          title: 'Tagihan Penjualan',
          link: '/sales/invoices',
          divider: true,
        },
        {
          id: 6,
          icon: 'mdi-account-tie',
          title: 'Nota Jatuh Tempo',
          link: '/sales/reached-due',
          divider: true,
          notification: false,
          forSalesman: true,
        },
        {
          id: 2,
          icon: 'mdi-cash-multiple',
          title: 'Pembayaran',
          link: '/sales/payments',
          divider: true,
        },
        {
          id: 3,
          icon: 'mdi-file-document-edit',
          title: 'Retur',
          link: '/sales/returns',
          divider: true,
        },
        {
          id: 4,
          icon: 'mdi-book',
          title: 'Piutang',
          link: '/sales/receivables',
          divider: true,
        },
        {
          id: 5,
          icon: 'mdi-account-tie',
          title: 'Salesman',
          link: '/sales/salesmans',
          forSalesman: true,
        },
      ],

      selected: null,
    }
  },

  mounted() {
    this.getSalesReachedDueDate()
  },

  methods: {
    getSalesReachedDueDate() {
      this.$http.get('/../notifications/get').then(({ data }) => {
        if (data.count > 0) {
          this.reports[1].notification = true
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.filter {
  margin-bottom: 20px;
}
</style>
